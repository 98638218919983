import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router";
import { servicePost } from "../../utils/api";

const SetPassword = () => {
    const {token} = useParams()
    const navigate = useNavigate()

    const [password,setPassword] = useState(null)

    const updatePassword = async ()=>{
        try {
            console.log(token)
            const {success,message} = await servicePost('api/v1/user/resetPassword',{
                token,
                password
            })
            if(!success) return toast.error(message)
            navigate('/login')
            return toast.success(message)
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    return ( 
        <div class="container mx-auto">
            <div class="flex justify-center px-6 my-12">
                <div class="w-full xl:w-3/4 lg:w-11/12 flex">
                    <div
                        class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
                        style={{ backgroundImage:`url("https://images.unsplash.com/photo-1558481795-7f0a7c906f5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1796&q=80)`}}
                    ></div>
                    <div class="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                        <div class="px-8 mb-4 text-center">
                            <h3 class="pt-4 mb-2 text-2xl">Set your account password</h3>
                            <p class="mb-4 text-sm text-gray-700">
                                Hey There, congratulations🎉 on getting your application approved.
                                Next step, setup your account password 
                            </p>
                        </div>
                        <form class="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                            <div class="mb-4">
                                <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
                                    Password
                                </label>
                                <input
                                    class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    onChange={e=>setPassword(e.target.value)}
                                    id="password"
                                    type="password"
                                    placeholder="Enter new password"
                                />
                            </div>
                            <div class="mb-6 text-center">
                                <button
                                    onClick={updatePassword}
                                    class="w-full px-4 py-2 font-bold text-white bg-purple-500 rounded-full hover:bg-purple-700 focus:outline-none focus:shadow-outline"
                                    type="button"
                                >
                                    Set Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SetPassword;