import { useParams } from "react-router";
import { useState, useEffect } from "react";
import * as axios from 'axios';
import toast from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { serviceGet } from "../utils/api";

const Bootcamp = () => {
    const state = {
        value: "Link comming soon",
        copied: false
    }
    const { id } = useParams();
    const [data, setData] = useState({});
    const [user, setUser] = useState({});
    const getBootcamps = async() => {
        try {
            const res = await serviceGet(`api/v1/bootcamp?id=${id}`, { headers: axios.defaults.headers.common })

            const { data: { bootcamp }, message, success } = res;
            setData(bootcamp);
        } catch (error) {
            console.log(error);
        }
    }
    const getUser = async() => {
        try {
            const res = await serviceGet(`api/v1/user/bootcamp/${id}`, { headers: axios.defaults.headers.common });
            const { data: { user }, message, success } = res
            console.log(user)
            setUser(user)
        } catch (error) {
            console.log(error)
        }
    }
    const getLink = () => {
        state.copied? toast.success("Link Copied!!"):toast.error("Failed to copy");
    }
    useEffect(()=>{
        getBootcamps();
        getUser();
    },[])
    return(
        <div class="bg-transperent flex flex-row justify-center">
            <div class="mt-6 pt-6 w-5/6 bg-white">
                <div class="mt-6 max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 flex flex-row justify-center gap-x-8" style={{height:'714px'}}>
                    <div class="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden lg:flex items-center" style={{width:'500px'}}>
                        <img src={data?.poster} alt="Two each of gray, white, and black shirts laying flat." class="w-full object-cover shadow-md"/>
                    </div>
                    <div class="hidden lg:grid lg:grid-cols-1 lg:gap-y-4 pt-3 w-5/6">
                    <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl text-center">Students Enrolled</h1>
                    <div className="container mx-auto px-6">
                        {/* Remove class [ h-64 ] when adding a card block */}
                        {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
                        <div className="w-full rounded border-dashed border-2 border-gray-300 overflow-y-scroll overflow-x-hidden" style={{height:'624px'}}>
                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">College</th>
                                                    <th scope="col" class="relative px-6 py-3">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    {
                                                            user?.students?.length > 0 ? user?.students?.map((student) => {
                                                                return (
                                                                    <tr>
                                                                        <td class="px-2 py-4 whitespace-nowrap">
                                                                            <div class="ml-4">
                                                                                <div class="text-sm font-medium text-gray-900">{`${student.firstName} ${student.lastName}`}</div>
                                                                                <div class="text-sm text-gray-500">{student.email}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-6 py-4 whitespace-nowrap">
                                                                            <div class="text-sm text-gray-900">{student.collegeName}</div>
                                                                            <div class="text-sm text-gray-500">{student.graduationYear}</div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }):(
                                                                <div style={{textAlign:"center",padding:"10px"}} >
                                                                    No students, have signed up from your referral link
                                                                </div>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="max-w-2xl mx-auto pt-10 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
                <div class="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                    <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl mb-6">{data?.name}</h1>
                </div>
                <div class="lg:mt-0 lg:row-span-3 flex items-center">
                    <CopyToClipboard
                        onCopy={()=>{
                            state.copied = true
                        }}
                        options={{message: 'Whoa!'}}
                        text={`https://www.devtown.in/events/${data?.slug||state.value}?ref=${user.referralCode}`}>
                        <button onClick={getLink} class="w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Get Referral Link</button>
                    </CopyToClipboard>
                </div>

                <div class="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                    <div>
                    <h3 class="sr-only">Description</h3>

                    <div class="space-y-6">
                        <p class="text-base text-gray-900">{data?.description}</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Bootcamp;