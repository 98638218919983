import axios from 'axios';

const SERVICE_URL = process.env.REACT_APP_BASE_URL
const TEST_MODE = window.location.href.includes("localhost")? true : false
if(TEST_MODE) console.log('Test mode Active !! ');

export const servicePost = async (path, payload, headers = null) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${(!TEST_MODE)?`${SERVICE_URL}/${path}`:`http://localhost:8083/${path}`}`, payload, {
                headers: headers,
            })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
};

export const serviceGet = async (path, headers) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${(!TEST_MODE)?`${SERVICE_URL}/${path}`:`http://localhost:8083/${path}`}`, {
                headers: headers
            })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
};
