import { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router';
import ErrorBoundaries from "./components/layout/ErrorBoundaries";
import axios from "axios";

import PrivateRoute from "./components/routing/Private";

import Bootcamps from "./components/Bootcamps";
import Bootcamp from "./components/Bootcamp";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import './App.css';
import Base from './components/layout/Base';
import SetPassword from "./components/auth/SetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import CompleteProfile from "./components/CompleteProfile";

function App() {

  return (
    <Base>
      <Routes>
        <Route path="/" element={
          <ErrorBoundaries.LoginErrorBoundary>
           <Bootcamps/>
          </ErrorBoundaries.LoginErrorBoundary>
        } />
        <Route path="/login" element={
          <ErrorBoundaries.LoginErrorBoundary>
            <Login />
          </ErrorBoundaries.LoginErrorBoundary>
        } />
        <Route path="/signUp" element={
          <ErrorBoundaries.SignUpErrorBoundary>
            <SignUp />
          </ErrorBoundaries.SignUpErrorBoundary>
        } />
        <Route path="/set-password/:token" element={
          <ErrorBoundaries.SignUpErrorBoundary>
            <SetPassword />
          </ErrorBoundaries.SignUpErrorBoundary>
        } />
        <Route path="/forgot-password" element={
          <ErrorBoundaries.SignUpErrorBoundary>
            <ForgotPassword />
          </ErrorBoundaries.SignUpErrorBoundary>
        } />
        <Route path="/bootcamp/:id" element={
          <PrivateRoute>
            <ErrorBoundaries.BootcampErrorBoundary>
              <Bootcamp />
            </ErrorBoundaries.BootcampErrorBoundary>
          </PrivateRoute>
        } />
        <Route path="/CompleteProfile" element={
          <PrivateRoute>
            <ErrorBoundaries.CompleteProfileErrorBoundary>
              <CompleteProfile />
            </ErrorBoundaries.CompleteProfileErrorBoundary>
          </PrivateRoute>
        } />
      </Routes>
    </Base>
  );
}

export default App;
