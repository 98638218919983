import axios from 'axios';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { servicePost } from '../utils/api';

const CompleteProfile = () => {
    const navigate = useNavigate();


    const handleSubmit = async(event) => {
        event.preventDefault();
        const form_data = new FormData(event.target);
        let values = {};
        form_data.forEach(function (value, key) {
            values[key] = value;
        });

        let collegeSociety = values.collegeSociety ? {
            isMember: values.collegeSociety != "",
            name: values.collegeSociety,
            position: values.societyPosition
        }:null

        let payload = {...values, collegeSociety}

        console.log("Payload ", payload);

        const res = await servicePost('api/v1/user/completeProfile', { ...payload }, axios.defaults.headers.common)
        const { message, success ,data:{user}} = res

        console.log(user)

        success ? toast.success("YAY!! 🎉 profile completed successfully, keep an 👁 on your inbox for our email.",{
            duration: 4000,
        }) : toast.error(message)

        user.profileCompleted?navigate('/'):toast.error("Need to complete the profile to proceed")
    };
    return (
        <form id="login" onSubmit={handleSubmit} className="flex flex-row justify-center">
            <div className="bg-white dark:bg-gray-800 w-23/25 mt-16 px-16 pt-8 mb-16" style={{maxWidth:'1000px'}}>
                <div className="container mx-auto bg-white dark:bg-gray-800 rounded">
                    <div className="xl:w-full border-b border-gray-300 dark:border-gray-700 py-5 bg-white dark:bg-gray-800">
                        <div className="flex w-11/12 mx-auto xl:w-full xl:mx-0 items-center">
                            <p className="text-lg text-gray-800 dark:text-gray-100 font-bold">DevTown Campus Ambassador Profile</p>
                            <div className="ml-2 cursor-pointer text-gray-600 dark:text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto">
                        {/* <div className="rounded relative mt-8 h-48 mb-8 flex flex-row justify-center">
                            <img src="https://lh4.googleusercontent.com/P-b7aFWQQo7NZz792K5YG72zo96goHfa9PGUaTVymf2DvAzLMD1BEnNbRfxBFWFMtFzfmmqjesigpVwhXIpgNVevL9jP8C8v1UUD6rHKD3AV-KE6xNfJb0bfAghnZVlvyg=w1198" alt className=" h-full rounded absolute shadow" />
                        </div> */}
                        <div className="xl:w-9/12 w-11/12 mx-auto xl:mx-0 mt-8">
                            
                            <div class="freebirdFormviewerViewHeaderHeaderBody">
                                <div class="freebirdFormviewerViewHeaderDescription" dir="auto">
                                    Hello there!<br></br>Greetings from DevTown. We at DevTown welcome you as our campus ambassador.<br></br> Please fill the form with all the details and also answer all the required questions. <br></br>Below are the responsibilities of a campus ambassador at DevTown :-<br></br>•	Encourage friends and your network to join DevTown.<br></br>•	Be the Face of DevTown.<br></br>•	Promote our Social Media handles and Bootcamps in your college.<br></br>•	Top Campus Ambassadors will get swags, t-shirts or hoodies from DevTown.<br></br>Note: In the college name, please write the AICTE/NIRF/Official name of your college present on the internet, as any other name may cause confusion.
                                </div>
                                <div jsname="F0H8Yc" class="freebirdCommonViewSecurequizSecureQuizBannerContainer"></div>
                            </div>
                            {/* <div className="mt-8 flex flex-col xl:w-2/6 lg:w-1/2 md:w-1/2 w-full">
                                <label htmlFor="username" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Username
                                </label>
                                <input type="text" id="username" name="username" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="@example" />
                            </div>
                            <div className="mt-8 flex flex-col xl:w-3/5 lg:w-1/2 md:w-1/2 w-full">
                                <label htmlFor="about" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    About
                                </label>
                                <textarea id="about" name="description" required className="bg-transparent border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="Let the world know who you are" rows={5} defaultValue={""} />
                                <p className="w-full text-right text-xs pt-1 text-gray-500 dark:text-gray-400">Character Limit: 200</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="container mx-auto bg-white dark:bg-gray-800 mt-10 rounded px-4">
                    <div className="xl:w-full border-b border-gray-300 dark:border-gray-700 py-5">
                        <div className="flex w-11/12 mx-auto xl:w-full xl:mx-0 items-center">
                            <p className="text-lg text-gray-800 dark:text-gray-100 font-bold">Personal Information</p>
                            <div className="ml-2 cursor-pointer text-gray-600 dark:text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto pt-4">
                        <div className="container mx-auto flex flex-wrap gap-10 justify-around">
                            {/* <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="First Name" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    First Name
                                </label>
                                <input type="text" id="firstName" name="firstName" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder />
                            </div>
                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="Last Name" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Last Name
                                </label>
                                <input type="text" id="lastName" name="lastName" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder />
                            </div>
                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="mobile" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Contact Number
                                </label>
                                <input type="tel" id="mobile" name="mobileNumber" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="XXXXXXXXXX" />
                            </div>
                            
                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="Email" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Email
                                </label>
                                <div className="border border-green-400 shadow-sm rounded flex">
                                    <div className="px-4 py-3 dark:text-gray-100 flex items-center border-r border-green-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <rect x={3} y={5} width={18} height={14} rx={2} />
                                            <polyline points="3 7 12 13 21 7" />
                                        </svg>
                                    </div>
                                    <input type="text" id="Email" name="email" required className="pl-3 py-3 w-full text-sm focus:outline-none placeholder-gray-500 rounded bg-transparent text-gray-500 dark:text-gray-400" placeholder="example@gmail.com" />
                                </div>
                            </div> */}
                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="college" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    College * <small> Enter the full name of your college </small>
                                </label>
                                <input type="text" id="college" name="collegeName" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="Birla Institute of technology and Science, Pilani" />
                            </div>
                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="mobile" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Whatsapp Number
                                </label>
                                <input type="tel" id="whatsapp" name="waNumber" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="XXXXXXXXXX" />
                            </div>
                            
                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="address" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Address <small>*This is where your goodies will be sent </small>
                                </label>
                                <textarea
                                placeholder={`B-10 Second Floor,\nBestech Park View,\nNew Delhi - 110006, India
                                `}      
                                type="text" rows={5} id="address" name="address" required className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400"  />
                            </div>

                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="inquiry" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    How do you know about DevTown ?
                                </label>
                                <select id="knowDevtown" required class="block w-52 py-3 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="heardFrom">
                                    <option value="Instagram">
                                        Instagram
                                    </option>
                                    <option value="WhatsappGroup">
                                        WhatsApp group
                                    </option>
                                    <option value="Facebook">
                                        Facebook
                                    </option>
                                    <option value="LinkedIn">
                                        LinkedIn
                                    </option>
                                    <option value="Twitter">
                                        Twitter
                                    </option>
                                    <option value="Friends or Family">
                                        Friends or Family
                                    </option>
                                </select>
                            </div>

                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                                <label htmlFor="society" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    College Society
                                </label>
                                <input type="text" id="collegeSociety" name="collegeSociety" className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="Fashion Society" />
                            </div>

                            <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6 self-start justify-self-start">
                                <label htmlFor="inquiry" className="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                                    Position in college society ? (*leave  if not a member)
                                </label>
                                <select id="societyPosition" class="block w-52 py-3 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500" defaultValue={'Member'} name="societyPosition">
                                    <option value="President">
                                        President
                                    </option>
                                    <option value="Vice President">
                                        Vice President
                                    </option>
                                    <option value="Secretary">
                                        Secretary
                                    </option>
                                    <option value="Member">
                                        Member
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto w-11/12 xl:w-full">
                    <div className="w-full py-4 sm:px-0 bg-white dark:bg-gray-800 flex justify-end">
                        <button className="bg-gray-200 focus:outline-none transition duration-150 ease-in-out hover:bg-gray-300 dark:bg-gray-700 rounded text-indigo-600 dark:text-indigo-600 px-6 py-2 text-xs mr-4" onClick={()=>toast.error('Need to complete the profile to proceed')}>Cancel</button>
                        <button className="bg-indigo-700 focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-2 text-sm" type="submit">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default CompleteProfile;