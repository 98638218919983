import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import { servicePost } from "../../utils/api";

const Login = ({}) => {
    
    const navigate = useNavigate();

   useEffect(()=>{
       const token = localStorage.getItem('token')
       if (token) {
           console.log(token)
           navigate('/')
       }
   },[])

    const handleSubmit = async(event) => {
        event.preventDefault();
        const form_data = new FormData(event.target);
        let values = {};
        form_data.forEach(function (value, key) {
            values[key] = value;
        });
        try {
            const res = await servicePost('api/v1/user/login', { ...values })
            const { data: { token, user }, message } = res
            if (token != null) {
                localStorage.setItem('token', token)
                axios.defaults.headers.common['auth'] = `bearer ${token}`;
                toast.success("login successfull");
                (!user.profileCompleted)?navigate('/CompleteProfile'):navigate('/');
            } else {
                delete axios.defaults.headers.common['auth'];
                toast.error(message)
            }
        } catch (error) {
            toast.error(error.message)
            delete axios.defaults.headers.common['auth'];
            console.log(error)
        }
        event.target.reset();
    };
    return(
        <div class="min-h-full flex items-center justify-center pb-60 px-4 sm:px-6 lg:px-8">
            <div class="max-w-md w-full space-y-8">
                <div>
                    <img class="mx-auto w-auto h-24" src="https://uploads-ssl.webflow.com/6077f96cf4fa19216396daaf/61bc66df6af55f5e768fedb1_stud_comm-02.svg" alt="Workflow"/>
                    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                </div>
                <form class="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <input type="hidden" name="remember" value="true"/>
                    <div class="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" class="sr-only">Email address</label>
                            <input id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address"/>
                        </div>
                        <div>
                            <label for="password" class="sr-only">Password</label>
                            <input id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password"/>
                        </div>
                    </div>

                    <div class="flex items-center justify-between">
                        {/* <div class="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
                            <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
                        </div> */}

                        <div class="text-sm">
                            <Link Link to="/forgot-password" class="font-medium text-indigo-600 hover:text-indigo-500"> Forgot your password? </Link>
                        </div>
                    </div>

                    <div>
                        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                                {/* <!-- Heroicon name: solid/lock-closed --> */}
                                <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                            Sign in
                        </button>
                        <br />
                        <h1 style={{textAlign:"center"}} >OR</h1>
                        <br />
                        <button onClick={()=>navigate('/signup')} type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                                {/* <!-- Heroicon name: solid/lock-closed --> */}
                                <svg class="h-5 w-5 text-red-500 group-hover:text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                            Sign Up
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;