import Login from "../auth/Login";
import { Navigate } from 'react-router-dom'

const PrivateRoute = (props) => {
    const isAuthenticated = localStorage.getItem('token')
    return ( 
        <div>
          {isAuthenticated ? props.children : <Navigate to='/login' />}
        </div>
     );
}
 
export default PrivateRoute;