import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { serviceGet } from "../utils/api";

const Bootcamps = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const getBootcamps = async() => {
        try {
            const res = await serviceGet('api/v1/bootcamp', { headers: axios.defaults.headers.common })
            const { data: { bootcamps }, message, success } = res;
            console.log(res)
            setData(bootcamps);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        console.log(axios.defaults.headers.common)

        if(!token){
            navigate('/login')
        }else{
            getBootcamps();

        }
    },[])
    return(
        <>
            <div className="container mx-auto px-6 pt-16">
                {/* Remove class [ h-64 ] when adding a card block */}
                {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
                <div className="w-full h-64 rounded border-dashed border-2 border-gray-300">
                    <div class="bg-white">
                        <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                            <h2 class="sr-only">Products</h2>
                            <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                                {data?.map((bootcamp)=>{
                                    return(
                                        <a class="group" onClick={()=>navigate(`/bootcamp/${bootcamp._id}`)}>
                                            <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 shadow-xl">
                                                <img src={bootcamp.poster} alt="Bootcamp Poster" class="object-center object-cover group-hover:opacity-75 shadow" style={{width:'400px', height:'400px'}}/>
                                            </div>
                                            <h3 class="mt-4 text-sm text-gray-700 ">{bootcamp.description}</h3>
                                            <p class="mt-1 text-lg font-medium text-gray-900">{bootcamp.name}</p>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bootcamps;