import { Component } from "react";
import Error from "./Error";

class LoginErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

class SignUpErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

class BootcampsErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}
class BootcampErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

class StudentsErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

class ProfileErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

class CompleteProfileErrorBoundary extends Component{

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log(error)
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }

    render(){
        if(this.state.hasError){
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

export default {
    LoginErrorBoundary, 
    SignUpErrorBoundary, 
    BootcampsErrorBoundary,
    BootcampErrorBoundary,
    StudentsErrorBoundary,
    ProfileErrorBoundary,
    CompleteProfileErrorBoundary
}