import { useState } from "react";
import { useNavigate } from "react-router";
import { Toaster } from 'react-hot-toast';
import { BsPersonCircle } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";

export default function Base(props) {
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className="absolute bg-gray-200 w-full h-full overflow-y-scroll overflow-x-hidden">
                {/* Navigation starts */}
                {/* Mobile */}
                <div className={show ? "w-full h-full absolute z-40  transform  translate-x-0 " : "   w-full h-full absolute z-40  transform -translate-x-full"}>
                    <div className="bg-gray-800 opacity-50 inset-0 fixed w-full h-full" onClick={() => setShow(!show)} />
                    <div className="w-64 z-20 absolute left-0 z-40 top-0 bg-white shadow flex-col justify-between transition duration-150 ease-in-out h-full">
                        <div className="flex flex-col justify-between h-full">
                            <div className="px-6 pt-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <Link to='/' >
                                            <img width={'120px'} src="https://uploads-ssl.webflow.com/6077f96cf4fa19216396daaf/61a1bee63c6e040a0dd33805_LOGO.svg" alt="" />
                                        </Link>
                                    </div>
                                    <div id="cross" className=" text-gray-800" onClick={() => setShow(!show)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <line x1={18} y1={6} x2={6} y2={18} />
                                            <line x1={6} y1={6} x2={18} y2={18} />
                                        </svg>
                                    </div>
                                </div>
                                <ul className="f-m-m">
                                    <a>
                                        <li className="text-white pt-8">
                                            <div className="flex items-center">
                                                <p className={`ml-3 text-lg ${window.location.pathname ==="/Bootcamps" ? "text-indigo-500" : "text-gray-700"}`} 
                                                onClick={()=>{
                                                    navigate('/');
                                                    setShow(!show);
                                                }}>
                                                    Bootcamps
                                                </p>
                                            </div>
                                        </li>
                                    </a>
                                    <a>
                                        <li className="text-gray-700 pt-8">
                                            <div className="flex items-center">
                                                <div className="flex items-center">
                                                    <p className={`ml-3 text-lg ${window.location.pathname ==="/Students" ? "text-indigo-500" : "text-gray-700"}`} 
                                                    onClick={()=>{
                                                        localStorage.removeItem('token')
                                                        navigate('/login')
                                                    }}>
                                                        Logout
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </a>
                                </ul>
                            </div>
                            <div className="w-full">
                                <div className="flex justify-center mb-4 w-full px-6">
                                    <div className="relative w-full">
                                        <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={16} height={16} viewBox="0 0 24 24" strokeWidth={1} stroke="#A0AEC0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <circle cx={10} cy={10} r={7} />
                                                <line x1={21} y1={21} x2={15} y2={15} />
                                            </svg>
                                        </div>
                                        <input className="bg-gray-100 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2" type="text" placeholder="Search" />
                                    </div>
                                </div>
                                {/* <div className={`border-t ${window.location.pathname ==="/Profile" ? "border-indigo-700":"border-gray-300"}`}>
                                    <div className="w-full flex items-center justify-between px-6 pt-1">
                                        <div className="flex items-center" onClick={()=>{
                                                navigate('/Profile');
                                                setShow(!show);
                                            }}>
                                            <IconContext.Provider
                                                value={{ color: window.location.pathname ==="/Profile" ? "#4338CA":"#718096", size: '25' }}
                                                >
                                                <div>
                                                    <BsPersonCircle />
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <ul className="flex">
                                            <li className="cursor-pointer text-white pt-5 pb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-messages" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke={`${window.location.pathname ==="/Profile" ? "#4338CA":"#718096"}`} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                    <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                                                    <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                                                </svg>
                                            </li>
                                            <li className="cursor-pointer text-white pt-5 pb-3 pl-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke={`${window.location.pathname ==="/Profile" ? "#4338CA":"#718096"}`} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                                                    <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Laptop */}
                <nav className="w-full mx-auto bg-white shadow">
                    <div className="container px-6 justify-between h-16 flex items-center lg:items-stretch mx-auto">
                        <div className="h-full flex items-center">
                            <div className="mr-10 flex items-center">
                               <Link to='/' >
                                    <img width={'120px'} src="https://uploads-ssl.webflow.com/6077f96cf4fa19216396daaf/61a1bee63c6e040a0dd33805_LOGO.svg" alt="" />
                                </Link>
                            </div>
                            {
                                window.location.pathname !="/Login" && window.location.pathname !="/SignUp" ? (
                                    <ul className="pr-12 xl:flex items-center h-full hidden">
                                        <li className={`cursor-pointer h-full flex items-center text-sm tracking-normal ${window.location.pathname ==="/Bootcamps" ? "text-indigo-700 border-b-2 border-indigo-700" : "text-gry-800"}`}
                                            onClick={() => navigate('/')}>
                                                Bootcamps
                                        </li>
                                        {/* <li className={`cursor-pointer h-full flex items-center text-sm tracking-normal mx-10 ${window.location.pathname ==="/Students" ? "text-indigo-700 border-b-2 border-indigo-700" : "text-gry-800"}`}
                                            onClick={() => navigate('/Students')}>
                                                Students Reffered
                                        </li> */}
                                    </ul>
                                ):(
                                    ""
                                )
                            }
                        </div>
                        {
                            window.location.pathname !="/Login" && window.location.pathname !="/SignUp" ? (
                                <div className="h-full xl:flex items-center justify-end hidden">
                                    <div className="w-full h-full flex items-center">
                                        <div className="w-full pr-12 h-full flex items-center border-r">
                                            {/* <div className="relative w-full">
                                                <div className="text-gray-500 absolute ml-3 inset-0 m-auto w-4 h-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <circle cx={10} cy={10} r={7} />
                                                        <line x1={21} y1={21} x2={15} y2={15} />
                                                    </svg>
                                                </div>
                                                <input className="border border-gray-100 focus:outline-none focus:border-indigo-700 w-56 rounded text-sm text-gray-500 bg-gray-100 pl-8 py-2" type="text" placeholder="Search" />
                                            </div> */}
                                        </div>
                                        {localStorage.getItem('token') && <div className="w-full h-full flex">
                                            <button onClick={() => {
                                                localStorage.removeItem('token')
                                                navigate('/login')
                                            }
                                            } className="mx-2 my-2 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-xs">Logout</button>
                                        </div>}
                                    </div>
                                </div>
                            ):(
                                ""
                            )
                        }
                        <div className="visible xl:hidden flex items-center relative">
                            {
                                window.location.pathname !="/Login" ? (
                                    <svg onClick={() => setShow(!show)} aria-label="Main Menu" aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1={4} y1={8} x2={20} y2={8} />
                                        <line x1={4} y1={16} x2={20} y2={16} />
                                    </svg>
                                ):(
                                    ""
                                )
                            }
                        </div>
                        {
                            window.location.pathname !="/Login" ? (
                                ""
                            ):(
                                <button onClick={()=>navigate('/SignUp')} className="mx-2 my-2 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-xs">SignUp</button>
                            )
                        }
                    </div>
                </nav>
                {/* Navigation ends */}
                {/* Page title starts */}

                { props.children }

            </div>
        </>
    );
}